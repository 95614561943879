
const STORAGE_KEY = "session.user"


/**
*handles Userlogin and logout
*/
export default class User {

  /**
  *Logs the User in. Callback("ok/alreadyLoggedIn/wrongPassword")
  */
  static async login(email, password, callback) {

    const data = {
      email,
      password
    }

    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/users/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async response => {
        if (response.status === 200) {
          const value = await response.json()
          localStorage.setItem(STORAGE_KEY, JSON.stringify(value))
          callback("ok")
          return
        }
        if (response.status === 406) {
          callback("alreadyLoggedIn")
          return
        }
        callback("wrongPassword")
      })
      .catch(e => console.error("ERROR:", e))
  }

  /**
  *Returns true if the user is logged in
  */
  static isAuthenticated() {
    const userData = this.getUser()
    return userData !== null
  }

  /**
  *Returns the User object
  */
  static getUser() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY))
  }

  /**
  *Changes the user Password. Callback(true/false)
  */
  static async changePassword(oldPassword, newPassword, callback = () => {}) {
    return new Promise((resolve) => {
      const data = {
        oldPassword,
        newPassword,
      }

      fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/users/password`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(async response => {
          if (response.status === 200) {
            callback(true)
            return resolve(true)
          }
          callback(false)
          resolve(false)
        })
        .catch(e => console.error("ERROR:", e))
    })

  }

  /**
  *Syncs the client User with the Server data
  */
  static async syncClient() {

    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/users`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async response => {
        if (response.status === 200) {
          const value = await response.json()
          localStorage.setItem(STORAGE_KEY, JSON.stringify(value))
          return
        }
        if (response.status === 406) {
          return
        }
      })
      .catch(e => console.error("ERROR:", e))
  }

  /**
  *Logs the User out
  */
  static async logout(email, password) {

    try {
      await fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/users/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      localStorage.removeItem(STORAGE_KEY)
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  /**
  *Registers a new account. Returns true when created and false if already exists
  */
  static async register(email, username, password, accessToken) {
    return new Promise(async (resolve) => {
      const data = {
        email,
        username,
        password,
        accessToken,
      }

      try {
        const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/users/register`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        return resolve(response.status)

      } catch (e) {
        console.log("ERROR", e);
      }

    }
    )
  }


}
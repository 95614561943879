import React from 'react'
import styles from './ArticleResult.module.scss'
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

export default function ArticleResult(props) {

  return (
    <div className={styles.result}>
      <div className={styles.wrapper}>
        <p className={styles.date}>{props.date.substring(0, 10)}</p>
        <Link to={"/article/" + props.id}>{props.name}</Link>
        <p className={styles.text}>
          {props.text}
        </p>
      </div>
    </div>
  )
}

import React from 'react'

import Center from '../../components/Center/Center'
import styles from './Index.module.scss'
import { Link } from 'react-router-dom'
import SearchBar from '../../components/SearchBar/SearchBar'
import Container from '../../components/Container/Container'

export default function Index() {
	return (
		<>
				<Center height="100vh">
					<Container size="large" margin="true">
					<SearchBar />
					<Link className={styles.new} to="/article/new/edit">Create new Artikel</Link>
					</Container>
				</Center>
		</>
	)
}
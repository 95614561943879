
/**
*handles Userlogin and logout
*/
export default class Article {

  /**
  *Gets a specific Article by its ID
  */
  static getFromId(id, callback) {
    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/article/${id}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch((e) => {
        console.error("ERROR", e);
      });
  }

  /**
  *Searches all Articles for a given keyword. Returns a Array of all matching Articles, sorted by priority
  */
  static search(keyword, callback) {
    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/search/?q=${keyword}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch((e) => {
        console.error("ERROR", e);
      });
  }

  /**
  *Returns all Articles created by the current User
  */
  static getUserArticles(callback) {
    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/article`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch((e) => {
        console.error("ERROR", e);
      });
  }

  /**
  *Updates a existing article or creates a new one if the id dosnt exists. Returns the updated database entry
  */
  static create(articleData, callback) {
    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/article`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(articleData),
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch((e) => {
        console.error("ERROR", e);
      });
  }

  /**
  *Updates a existing article with the new Json
  */
  static update(articleData, id, callback) {
    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/article/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(articleData),
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch((e) => {
        console.error("ERROR", e);
      });
  }

  /**
  *removes a Article with the ID
  */
  static delete(id, callback) {
    fetch(`${import.meta.env.VITE_SERVER_URL}/api/v1/article/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch((e) => {
        console.error("ERROR", e);
      });
  }

}
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router"
import styles from './ArticleEdit.module.scss'
import Markdown from '../../components/Markdown/Markdown'
import Editor from "@monaco-editor/react"
import { useSessionStorage } from "../../hooks/useStorage/useStorage"
import useDebounce from "../../hooks/useDebounce/useDebounce"
import { renderSnipped } from "../../components/Markdown/renderMarkdown"
import Button from '../../components/Button/Button'
import Switch from '../../components/Switch/Switch'
import Icon from '../../components/Icon/Icon'
import ArticleApi from '../../api/article'
import UserApi from '../../api/user'
import { Helmet } from "react-helmet"


export default function ArticleEdit(props) {

  let isCached = true;
  const history = useHistory();
  const user = UserApi.getUser()

  const { id } = props.match.params
  const [markdown, setMarkdown] = useState("")
  const [title, setTitle] = useState("")
  const [owner, setOwner] = useState()
  const [isVisible, setIsVisible] = useState(false)
  const [isShared, setIsShared] = useState(false)
  const [cachedArticle, setCachedArticle, removeCachedArticle] = useSessionStorage(
    `article.${id}.edit`, generateNewArticle)
  
  useDebounce(cacheArticle, 500, [markdown, title, isVisible, isShared])

  useEffect(() => {
    if (isCached) {
      loadArticleFromCache()
      return
    }
    ArticleApi.getFromId(id, data => setArticleFromJSON(data))
  }, [])

  function canEdit() {
    return (user.role === "admin" || user._id === owner)
  }

  function generateNewArticle() {
    isCached = false;
    return {
      title: "New Title",
      markdown: "",
      isShared: isShared,
      isVisible: isVisible
    }
  }

  function loadArticleFromCache() {
    setMarkdown(cachedArticle.markdown)
    setTitle(cachedArticle.title)
    setIsVisible(cachedArticle.isVisible)
    setIsShared(cachedArticle.isShared)
    setOwner(cachedArticle.owner)
  }

  function cacheArticle() {
    setCachedArticle(getArticleJSON())
  }

  function getArticleJSON() {
    return {
      title: title,
      markdown: markdown,
      isShared: isShared,
      isVisible: isVisible,
      owner: owner,
    }
  }

  function setArticleFromJSON(data) {
    setTitle(data.title)
    setMarkdown(data.markdown)
    setIsVisible(data.isVisible)
    setIsShared(data.isShared)
    setOwner(data.owner)
  }

 function createSaveData() {
    let saveData = getArticleJSON()
    saveData.snipped = renderSnipped(cachedArticle.markdown)
    return saveData
  }

  function handleEditorWillMount(monaco) {
    //TODO: handle editor theming
  }

  function handleCancelClick() {
    removeCachedArticle();
    const url = id === "new" ? "/" : `/article/${id}`
    history.push({ pathname: url })
  }

  function redirectAfterSave(data) {
    removeCachedArticle();
    const url = `/article/${data._id}`
    history.push({ pathname: url })
  }

  function handleSaveClick() {
    const saveData = createSaveData()
    id === "new" ?
      ArticleApi.create(saveData, redirectAfterSave) :
      ArticleApi.update(saveData, id, redirectAfterSave)
  }

  return (
    <>
      <Helmet>
        <title>{title} - NickiWiki</title>
      </Helmet>
    <div className={styles.mainGrid}>
      <div className={styles.top}>
        <input
          type="text"
          className={styles.header}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {canEdit() &&
          <>
            <Switch
              on={<Icon color="var(--black)" size="20px" frame="25px" name="far fa-eye" />}
              off={<Icon color="var(--gray)" size="20px" frame="25px" name="far fa-eye-slash" />}
              onChange={(e) => { setIsVisible(e) }}
              value={isVisible}
            />
            <Switch
              on={<Icon color="var(--black)" size="20px" frame="25px" name="fas fa-users" />}
              off={<Icon color="var(--gray)" size="20px" frame="25px" name="fas fa-users-slash" />}
              onChange={(e) => { setIsShared(e) }}
              value={isShared}
            />
          </>
        }
        <Button onClick={handleSaveClick}>Save</Button>
        <Button onClick={handleCancelClick} type="outline">Cancel</Button>
      </div>
      <Editor
          height="100%"
          width="100%"
          defaultLanguage="markdown"
          value={markdown}
          beforeMount={handleEditorWillMount}
          onChange={(text) => setMarkdown(text)}
          options={{ wordWrap: "on" }}
        //theme="myCustomTheme"
      />
      <Markdown className={styles.md}>{markdown}</Markdown>
      </div>
    </>
  )
}



// Module Includes
//=============================================================================================
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

// Route Includes
//=============================================================================================
import Index from './routes/Index/Index'
import Search from './routes/Search/Search'
import Login from './routes/Login/Login'
import Register from './routes/Register/Register'
import Article from './routes/Article/Article'
import ArticleEdit from './routes/Article/ArticleEdit'
import UserSettings from './routes/UserSettings/UserSettings'
import NotFound from "./routes/404/NotFound"
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { Helmet } from "react-helmet"

function App() {

  return (
    <>
      <Helmet>
        <title>NickiWiki</title>
      </Helmet>
      <Router>
        <ScrollMemory />
        <Switch> {/* sorgt dafür dass nur eine rout genommen wird */}
          <ProtectedRoute path="/" exact component={Index} />
          <ProtectedRoute path="/search" exact component={Search} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <ProtectedRoute path="/article/:id" exact component={Article} />
          <ProtectedRoute path="/article/:id/edit" exact component={ArticleEdit} />
          <ProtectedRoute path="/usersettings" exact component={UserSettings} />
          {/* 404 route */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;

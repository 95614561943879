import React from 'react'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { Link } from 'react-router-dom';

export default function NewArticleButton() {
  return (
    <Link to="/article/new/edit">
      <Button type="outline">
        <Icon name="fas fa-plus" /> New
      </Button>
    </Link>
  );
}

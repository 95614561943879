import React, { useState, useRef } from 'react'
import { useHistory } from "react-router";
import Center from '../../components/Center/Center'
import styles from './Login.module.scss'
import { Link } from 'react-router-dom'
import Container from '../../components/Container/Container'
import Button from '../../components/Button/Button'
import User from '../../api/user'

export default function Login() {

  const history = useHistory()
  const emailRef = useRef()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loginStatus, setLoginStatus] = useState("")

  function handleButtonPress(e) {
    e.preventDefault()
    handleLogin()
  }

  function handleLogin() {
    User.login(email, password, (status => {
      setLoginStatus(status)
      if (status === "ok") {
        history.push({ pathname: "/" })
      }
      else {
        setPassword("")
        emailRef.current.focus()
      }
    }))
  }

  return (
    <Center height="100vh">
      <Container size="large" margin="true">
        {loginStatus === "wrongPassword" && <div className={styles.error}>Wrong Password or Email</div>}
        {loginStatus === "alreadyLoggedIn" && <div className={styles.error}>Already logged in</div>}
        <form className={styles.form} >
          <input
            autoFocus
            required
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            ref={emailRef} 
          >
          </input>
          <input
            required
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          >
          </input>
          <Button onClick={handleButtonPress} >Login</Button>
        </form>
        <Link className={styles.new} to="/register">Create new Account</Link>
      </Container>
    </Center>
  )
}
import MarkdownIt from 'markdown-it'
import sanitizeHtml from 'sanitize-html'
import sanitize from './sanitize';


var hljs = require('highlight.js')
var striptags = require('striptags');

const handleHightlight = function (str, lang) {
  if (lang && hljs.getLanguage(lang)) {
    try {
      return hljs.highlight(str, { language: lang }).value;
    } catch (__) { }
  }
  return '';
}

const md = new MarkdownIt({
  html: true,               // Enable HTML tags in source
  xhtmlOut: true,           // Use '/' to close single tags (<br />).
  breaks: true,             // Convert '\n' in paragraphs into <br>
  langPrefix: 'language-',  // CSS language prefix for fenced blocks.
  linkify: true,            // Autoconvert URL-like text to links
  typographer: false,
  highlight: handleHightlight
})

const htmlSanitizerConfig = {
  allowedTags: [
    "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
    "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
    "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
    "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
    "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
    "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
    "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "iframe",
    "img"
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: ['src', 'height', 'width'],
    iframe: ['src', 'height', 'width']
  }
}

md.use(require("markdown-it-anchor").default)
md.use(require("markdown-it-table-of-contents"))
md.use(require("@vscode/markdown-it-katex"));
md.use(require('markdown-it-code-copy'), {
  iconClass: "far fa-copy",
  buttonClass: "",
  iconStyle: ""
});
md.use(sanitize)
md.use(require('markdown-it-multimd-table'), {
  multiline: true,
  rowspan: true,
  headerless: true,
})
//md.use(require("./markdown-it-todo"));

function replaceMathSymbols(input) {
  // Replace \( and \) with $
  let output = input.replace(/\\\(\s*/g, "$").replace(/\s*\\\)/g, "$");

  // Replace \[ and \] with $$. We need double $$ because JS escapes the $ sign.
  output = output.replace(/\\\[/g, `$$$$`).replace(/\\\]/g, `$$$$`);
  return output;
}

export function renderMarkdown(raw) {
  //const clean = sanitizeHtml(raw, htmlSanitizerConfig)
  const convertedRaw = replaceMathSymbols(raw)
  return md.render(convertedRaw);
}

export function renderSnipped(raw) {
  const html = md.render(raw)
  const strip = striptags(html);
  const str = strip.replace(/(\r\n|\n|\r)/gm, " ");
  return (str.substring(0, Math.min(200, str.length)) + "...")
}
// Sanitizer

"use strict";

// Assuming `md` is passed in from an external library, import it as needed.
// import { linkify, utils } from 'some-markdown-library'; // Adjust this based on your environment

export default function sanitizer_plugin(md, options) {
  const linkify = md.linkify,
    escapeHtml = md.utils.escapeHtml,
    // <a href="url" title="(optional)"></a>
    patternLinkOpen = '<a\\s([^<>]*href="[^"<>]*"[^<>]*)\\s?>',
    regexpLinkOpen = new RegExp(patternLinkOpen, "i"),
    // <img src="url" alt=""(optional) title=""(optional)>
    patternImage = '<img\\s([^<>]*src="[^"<>]*"[^<>]*)\\s?\\/?>',
    regexpImage = new RegExp(patternImage, "i"),
    regexpImageProtocols = /^(?:https?:)?\/\//i,
    regexpLinkProtocols = /^(?:https?:\/\/|ftp:\/\/|\/\/|mailto:|xmpp:)/i;

  options = options || {};
  const removeUnknown =
    typeof options.removeUnknown !== "undefined" ? options.removeUnknown : false;
  const removeUnbalanced =
    typeof options.removeUnbalanced !== "undefined" ? options.removeUnbalanced : false;
  const imageClass = typeof options.imageClass !== "undefined" ? options.imageClass : "";
  let runBalancer = false;

  const allowedTags = [
    "a",
    "b",
    "blockquote",
    "code",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "li",
    "ol",
    "p",
    "pre",
    "s",
    "sub",
    "sup",
    "strong",
    "ul",
  ];
  const openTagCount = new Array(allowedTags.length).fill(0);
  const removeTag = new Array(allowedTags.length).fill(false);

  function getUrl(link) {
    const match = linkify.match(link);
    if (match && match.length === 1 && match[0].index === 0 && match[0].lastIndex === link.length) {
      return match[0].url;
    }
    return null;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  //          REPLACE UNKNOWN TAGS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function replaceUnknownTags(str) {
    return str.replace(/<[^<>]*>?/gi, (tag) => {
      let match, attrs, url, alt, title, tagnameIndex;

      // '<->', '<- ' and '<3 ' look nice, they are harmless
      if (/(^<->|^<-\s|^<3\s)/.test(tag)) return tag;

      // images
      match = tag.match(regexpImage);
      if (match) {
        attrs = match[1];
        url = getUrl(attrs.match(/src="([^"<>]*)"/i)?.[1]);
        alt = attrs.match(/alt="([^"<>]*)"/i)?.[1] || "";
        title = attrs.match(/title="([^"<>]*)"/i)?.[1] || "";

        // only http and https are allowed for images
        if (url && regexpImageProtocols.test(url)) {
          if (imageClass !== "") {
            return `<img src="${url}" alt="${alt}" title="${title}" class="${imageClass}">`;
          }
          return `<img src="${url}" alt="${alt}" title="${title}">`;
        }
      }

      // links
      tagnameIndex = allowedTags.indexOf("a");
      match = tag.match(regexpLinkOpen);
      if (match) {
        attrs = match[1];
        url = getUrl(attrs.match(/href="([^"<>]*)"/i)?.[1]);
        title = attrs.match(/title="([^"<>]*)"/i)?.[1] || "";
        // only http, https, ftp, mailto, and xmpp are allowed for links
        if (url && regexpLinkProtocols.test(url)) {
          runBalancer = true;
          openTagCount[tagnameIndex] += 1;
          return `<a href="${url}" title="${title}" target="_blank">`;
        }
      }

      match = /<\/a>/i.test(tag);
      if (match) {
        runBalancer = true;
        openTagCount[tagnameIndex] -= 1;
        if (openTagCount[tagnameIndex] < 0) {
          removeTag[tagnameIndex] = true;
        }
        return "</a>";
      }

      // standalone tags
      match = tag.match(/<(br|hr)\s?\/?>/i);
      if (match) {
        return `<${match[1].toLowerCase()}>`;
      }

      // whitelisted tags
      match = tag.match(
        /<(\/?)(b|blockquote|code|em|h[1-6]|li|ol(?: start="\d+")?|p|pre|s|sub|sup|strong|ul)>/i
      );
      if (match && !/<\/ol start="\d+"/i.test(tag)) {
        runBalancer = true;
        tagnameIndex = allowedTags.indexOf(match[2].toLowerCase().split(" ")[0]);
        if (match[1] === "/") {
          openTagCount[tagnameIndex] -= 1;
        } else {
          openTagCount[tagnameIndex] += 1;
        }
        if (openTagCount[tagnameIndex] < 0) {
          removeTag[tagnameIndex] = true;
        }
        return `<${match[1]}${match[2].toLowerCase()}>`;
      }

      // other tags we don't recognize
      if (removeUnknown === true) {
        return "";
      }
      return escapeHtml(tag);
    });
  }

  function sanitizeInlineAndBlock(state) {
    let blkIdx, inlineTokens;
    openTagCount.fill(0);
    removeTag.fill(false);
    runBalancer = false;

    for (blkIdx = 0; blkIdx < state.tokens.length; blkIdx++) {
      if (state.tokens[blkIdx].type === "html_block") {
        state.tokens[blkIdx].content = replaceUnknownTags(state.tokens[blkIdx].content);
      }
      if (state.tokens[blkIdx].type !== "inline") continue;

      inlineTokens = state.tokens[blkIdx].children;
      for (let i = 0; i < inlineTokens.length; i++) {
        if (inlineTokens[i].type === "html_inline") {
          inlineTokens[i].content = replaceUnknownTags(inlineTokens[i].content);
        }
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  //          REPLACE UNBALANCED TAGS
  /////////////////////////////////////////////////////////////////////////////////////////////////

  function balance(state) {
    if (!runBalancer) return;

    const replaceUnbalancedTag = (str, tagname) => {
      let openingRegexp, closingRegexp;
      if (tagname === "a") {
        openingRegexp = /<a href="[^"<>]*" title="[^"<>]*" target="_blank">/g;
      } else if (tagname === "ol") {
        openingRegexp = /<ol(?: start="\d+")?>/g;
      } else {
        openingRegexp = new RegExp(`<${tagname}>`, "g");
      }
      closingRegexp = new RegExp(`</${tagname}>`, "g");

      if (removeUnbalanced === true) {
        str = str.replace(openingRegexp, "").replace(closingRegexp, "");
      } else {
        str = str
          .replace(openingRegexp, (m) => escapeHtml(m))
          .replace(closingRegexp, (m) => escapeHtml(m));
      }
      return str;
    };

    const replaceAllUnbalancedTags = (str) => {
      allowedTags.forEach((tag, i) => {
        if (removeTag[i] === true) {
          str = replaceUnbalancedTag(str, tag);
        }
      });
      return str;
    };

    for (let blkIdx = 0; blkIdx < state.tokens.length; blkIdx++) {
      if (state.tokens[blkIdx].type === "html_block") {
        state.tokens[blkIdx].content = replaceAllUnbalancedTags(state.tokens[blkIdx].content);
        continue;
      }
      if (state.tokens[blkIdx].type !== "inline") continue;

      const inlineTokens = state.tokens[blkIdx].children;
      for (let j = 0; j < inlineTokens.length; j++) {
        if (inlineTokens[j].type === "html_inline") {
          inlineTokens[j].content = replaceAllUnbalancedTags(inlineTokens[j].content);
        }
      }
    }
  }

  md.core.ruler.after("linkify", "sanitize_inline", sanitizeInlineAndBlock);
  md.core.ruler.after("sanitize_inline", "sanitize_balance", balance);
}
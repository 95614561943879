import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import styles from './Article.module.scss'
import Container from '../../components/Container/Container'
import SearchBar from '../../components/SearchBar/SearchBar'
import Icon from '../../components/Icon/Icon'
import Markdown from '../../components/Markdown/Markdown'
import User from '../../components/User/User';
import ArticleApi from '../../api/article'
import UserApi from '../../api/user'
import { Helmet } from "react-helmet"
import NewArticleButton from '../../components/NewArticleButton/NewArticleButton';


export default function Article(props) {

  const [markdown, setMarkdown] = useState("Loading...")
  const [title, setTitle] = useState("Loading...")
  const [owner, setOwner] = useState()
  const [isShared, setIsShared] = useState(false)
  const { id } = props.match.params
  const user = UserApi.getUser()

  useEffect(() => {
    ArticleApi.getFromId(id, data => {
      setTitle(data?.title ?? "Missing Title")
      setMarkdown(data?.markdown ?? "")
      setOwner(data?.owner)
      setIsShared(data?.isShared)
    })
  }, [id])

  function canEdit() {
    return (user.role === "admin" || user._id === owner || isShared)
  }

  return (
    <>
      <Helmet>
        <title>{title} - NickiWiki</title>
      </Helmet>
      <Container margin="true" size="small">
        <div className={styles.top}>
          <h1 className={styles.header}>{title}
            {canEdit() &&
              <Link className={styles.edit} to={id + "/edit"}>
                <Icon name="fas fa-pen" />
              </Link>}
          </h1>
          <SearchBar />
          <NewArticleButton />
          <User/>
        </div>
        <Markdown>{markdown}</Markdown>
      </Container>
    </>
  )
}

import React from 'react'
import { Redirect, Route } from 'react-router'
import UserApi from '../../api/user'

export default function ProtectedRoute({ component: Component, ...rest }) {

  return (
    <Route {...rest} render={props => (
      UserApi.isAuthenticated()
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
    )}
    />
  )

}

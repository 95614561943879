import React, { useState, useRef } from 'react'
import { useHistory } from "react-router";
import Center from '../../components/Center/Center'
import styles from './Register.module.scss'
import { Link } from 'react-router-dom'
import Container from '../../components/Container/Container'
import Button from '../../components/Button/Button'
import User from '../../api/user'

export default function Register() {

  const history = useHistory()
  const emailRef = useRef()

  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [retypedPassword, setRetypedPassword] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [error, setError] = useState("")

  function handleButtonPress(e) {
    e.preventDefault()
    handleRegister()
  }

  async function handleRegister() {
    if (username === "") return setError("Username cannot be empty")
    if (email === "") return setError("Email cannot be empty")
    if (password === "") return setError("Password cannot be empty")
    if (password !== retypedPassword) return setError("Passwords do not match")

    const result = await User.register(email, username, password, accessToken)
    if (result === 409) return setError("Email address is already in use")
    if (result === 403) return setError("Invalid Token")
    if (result === 200) return history.push({ pathname: "/login" })
    setError(`ERRORCODE: ${result}`)
  }

  return (
    <Center height="100vh">
      <Container size="large" margin="true">
        {error !== "" && <div className={styles.error}>{error}</div>}
        <form className={styles.form} >
          <input
            autoFocus
            required
            type="text"
            placeholder="Name"
            value={username}
            onChange={e => setUsername(e.target.value)}
            ref={emailRef}
          />
          <input
            required
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            ref={emailRef}
          />
          <input
            required
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <input
            required
            type="password"
            placeholder="Retype Password"
            value={retypedPassword}
            onChange={e => setRetypedPassword(e.target.value)}
          />
          <p className={styles.tokentext}>
            Only privileged users are allowed to create an account here,
            which is why an access token is required for registration.
          </p>
          <input
            required
            type="text"
            placeholder="Access Token"
            value={accessToken}
            onChange={e => setAccessToken(e.target.value)}
          />
          <Button onClick={handleButtonPress} >Register new account</Button>
        </form>
        <Link className={styles.new} to="/login">Already registered</Link>
      </Container>
    </Center>
  )
}
import React from 'react'
import styles from './Markdown.module.scss'
import { renderMarkdown } from './renderMarkdown'
import './atom-one-dark.css'
import './code-copy.scss'
//import './katex.min.css' 

export default function Markdown(props) {
  const markdownHTML = renderMarkdown(props.children)

  return (
    <div {...props}>
      <div className={styles.markdown} dangerouslySetInnerHTML={{ __html: markdownHTML }} />
    </div>
     )
}
